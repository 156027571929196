export const getLogoByDomain = () => {
    const domainLogoMapping = {
        'ag.onraplay.com': '/logos/logo.png',
        'ag.dream444.in': '/logos/dream444.png',
        'ag.dream2025.in': '/logos/dream2025.png',
        'ag.jmt777.com': '/logos/jmt777.png',
        'ag.dreamn444.com': '/logos/dreamn444.png',
        'ag.rajexch.in': '/logos/rajexch.png'
    };

    // Get the current domain
    const currentDomain = window.location.hostname;

    // Return the corresponding logo or a default one
    return domainLogoMapping[currentDomain] || '/logos/logo.png';
};
